import { LanguageDefinitionsEn } from './models/language-definitions-en';
import { LanguageDefinitionsDe } from './models/language-definitions-de';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})

export class TextService {

    public navigation_home: string;
    public navigation_about: string;
    public navigation_themes: string;
    public navigation_vision: string;
    public navigation_events: string;
    public navigation_contact: string;

    public home_about_header: string;
    public home_about_content1: string;
    public home_about_content2: string;
    public home_about_content3: string;
    public home_about_content4: string;
    public home_about_content5: string;
    public home_about_content6: string;
    public home_about_quote: string;
    public home_about_signature: string;

    public home_themes_header: string;
    public home_themes_blockchain_header: string;
    public home_themes_blockchain_description: string;
    public home_themes_crypto_header: string;
    public home_themes_crypto_description: string;
    public home_themes_icos_header: string;
    public home_themes_icos_description: string;
    public home_themes_defi: string;

    public home_cryptofit_header: string;
    public home_cryptofit_subheader: string;
    public home_cryptofit_content1: string;
    public home_cryptofit_content2: string;
    public home_cryptofit_content3: string;
    public home_cryptofit_quote: string;

    public home_events_header: string;

    public home_forbes_header: string;
    public home_forbes_description: string;

    public home_contact_header: string;


    de: LanguageDefinitionsDe;
    en: LanguageDefinitionsEn;
    isDe: boolean;

    constructure() { }

    load() {
        this.de = new LanguageDefinitionsDe();
        this.en = new LanguageDefinitionsEn();
        this.isDe = true;

        for (let property in this.de) {
            this[property] = this.de[property];
        }
    }

    changeLanguage() {
        if (this.isDe) {
            for (let property in this.en) {
                this[property] = this.en[property];
            }
        } else {
            for (let property in this.de) {
                this[property] = this.de[property];
            }
        }
        this.isDe = !this.isDe;
    }
}