import { Component, OnInit } from '@angular/core';
import { TextService } from 'src/app/services/text-service.service';
import { EventData } from '../../services/models/event-data';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.css']
})
export class EventsComponent implements OnInit {

  constructor(public textDefinitions: TextService, public eventData: EventData) { }

  ngOnInit() {
  }

}
