import { Component, OnInit } from '@angular/core';
import { Router, Event, NavigationEnd, ActivatedRoute } from '@angular/router';
import { HostListener } from '@angular/core';
import $ from "jquery";
import { TextService } from '../services/text-service.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements OnInit {

  public transparentBackground = false;
  public selectedSection: string;

  constructor(private router: Router, private route: ActivatedRoute, public textDefinitions: TextService) {
    $(function () {
      $('.nav a').on('click', function () {
        if ($('#navbarToggler').css('display') != 'none') {
          $('#navbarToggler').trigger("click");
        }
      });
    });
  }

  ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.transparentBackground = this.router.url.toString().includes('/home');
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0; // For IE and Firefox 
      }
    });
  }

  navigateTo(id: string) {
    let previousRoute = this.router.url.toString();
    console.log(previousRoute);

    if (previousRoute.includes('home')) {
      let el = document.getElementById(id);
      let headerOffset = 100;
      if (id == "start") {
        headerOffset = 0;
      }
      let bodyPosition = document.body.getBoundingClientRect().top;
      let elRect = el.getBoundingClientRect().top;
      let elPosition = elRect - bodyPosition;
      let offsetPosition = elPosition - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
      });

    } else {
      this.router.navigate(['home']);
      this.router.events.subscribe((event: Event) => {
        if (event instanceof NavigationEnd) {
          if (id == "start") {
            this.transparentBackground = true;
          }

          document.body.scrollTop = 0;
          document.documentElement.scrollTop = 0; // For IE and Firefox

          if (this.router.url.toString().includes('/home')) {
            let el = document.getElementById(id);
            el.scrollIntoView();
          }
        }
      });
    }
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(event) {
    if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
      this.transparentBackground = false;
    } else {
      this.transparentBackground = this.router.url.toString().includes('/home');
    }
  }

  changeLanguage() {
    this.textDefinitions.changeLanguage();
  }
}
