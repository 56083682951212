import { Component, OnInit } from '@angular/core';
import { TextService } from '../services/text-service.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(public textDefinitions : TextService) { }

  ngOnInit() {
  }

  scroll() {
    let el = document.getElementById('about');
    el.scrollIntoView();
  }

}
