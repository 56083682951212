import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ScrollDispatchModule } from '@angular/cdk/scrolling';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavigationComponent } from './navigation/navigation.component';
import { HomeComponent } from './home/home.component';
import { LegalnoticeComponent } from './legalnotice/legalnotice.component';
import { PrivacypolicyComponent } from './privacypolicy/privacypolicy.component';
import { FooterComponent } from './footer/footer.component';
import { ThemeblockComponent } from './themeblock/themeblock.component';

import { TextService } from './services/text-service.service';
import { StartComponent } from './home/start/start.component';
import { AboutComponent } from './home/about/about.component';
import { ThemesComponent } from './home/themes/themes.component';
import { VisionComponent } from './home/vision/vision.component';
import { EventsComponent } from './home/events/events.component';
import { ContactComponent } from './home/contact/contact.component';
import { ImageHoverComponent } from './components/image-hover/image-hover.component';

@NgModule({
  declarations: [
    AppComponent,
    NavigationComponent,
    HomeComponent,
    LegalnoticeComponent,
    PrivacypolicyComponent,
    FooterComponent,
    ThemeblockComponent,
    StartComponent,
    AboutComponent,
    ThemesComponent,
    VisionComponent,
    EventsComponent,
    ContactComponent,
    ImageHoverComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ScrollDispatchModule
  ],
  providers: [TextService],
  bootstrap: [AppComponent]
})
export class AppModule { }
