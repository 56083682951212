import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-image-hover',
  templateUrl: './image-hover.component.html',
  styleUrls: ['./image-hover.component.scss']
})
export class ImageHoverComponent implements OnInit {

  @Input() RefLink: string;
  @Input() BackgroundImage: string;
  @Input() Caption: string;
  @Input() Description: string;

  constructor() { }

  ngOnInit() {
  }

}
