import { SquareDisplayModule } from './SquareDisplayModule';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})

export class EventData {
  public data: SquareDisplayModule[] = [
    {
      link: 'https://www.fh-kufstein.ac.at/',
      title: 'FH Kufstein, Jänner 2024',
      description: 'Lektor: Crypto Finance',
      image: 'assets/events/FHKufsteinlogo.jpg'
    },
    {
      link: 'https://www.fh-kufstein.ac.at/',
      title: 'FH Kufstein, November 2023',
      description: 'Lektor: Start Ups und Digitale Business Modelle',
      image: 'assets/events/FHKufsteinlogo.jpg'
    },
    {
      link: 'https://www.entrepreneurshipwoche.at/',
      title: 'Youth Entrepreneurship Week, September 2023',
      description: 'Start Up Coach und Start Up Juror',
      image: '../../assets/events/ent_youth.png'
    },
    {
      link: 'https://www.uibk.ac.at/fakultaeten/rechtswissenschaftliche/uk-it-recht-digitalisierung/',
      title: 'Universität Innsbruck, Juni 2023',
      description: 'Lektor: IT Recht und Digitalisierung',
      image: 'assets/events/uni-innsbruck.jpg'
    },
    {
      link: 'https://www.fh-kufstein.ac.at/',
      title: 'FH Kufstein, Mai 2023',
      description: 'Lektor: Trends in International Finance',
      image: 'assets/events/FHKufsteinlogo.jpg'
    },
    {
      link: 'https://www.fh-kufstein.ac.at/',
      title: 'FH Kufstein, April 2023',
      description: 'Lektor: Contemporary Issues of Finance',
      image: 'assets/events/FHKufsteinlogo.jpg'
    },
    {
      link: 'https://skinnovation.io/',
      title: 'Skinnovation, März 2023',
      description: 'Panel: Web 3.0 und DeFi Discussion',
      image: '../../assets/events/skinnovation.jpg'
    },
    {
      link: 'https://bconf.de/',
      title: 'Bitcoin Conference Austria, September 2022',
      description: 'Business Angel',
      image: '../../assets/events/btc22.png'
    },
    {
      link: 'https://www.entrepreneurshipwoche.at/',
      title: 'Youth Entrepreneurship Week, September 2022',
      description: 'Start Up Coach und Start Up Juror',
      image: '../../assets/events/ent_youth.png'
    },
    {
      link: 'https://www.fh-kufstein.ac.at/',
      title: 'FH Kufstein, Juli 2022',
      description: 'Lektor: International Accounting and Finance',
      image: 'assets/events/FHKufsteinlogo.jpg'
    },
    {
      link: 'https://www.tirol.wifi.at/kurs/34190x-general-management',
      title: 'Wifi Tirol, Juni 2022',
      description: 'Lektor: Investition und Finanzierung',
      image: '../../assets/events/wifi.jpg'
    },
    {
      link: 'https://epu.wktirol.at/',
      title: 'Wirtschaftskammer Tirol, Mai 2022',
      description: 'Keynote: Mysterium Blockchain: Hit oder Hype?',
      image: '../../assets/events/wirtschaftskammer.png'
    },
    {
      link: 'https://skinnovation.io/',
      title: 'Skinnovation, März 2022',
      description: 'Panel: Web 3.0 und DeFi Discussion',
      image: '../../assets/events/skinnovation.jpg'
    },
    {
      link: 'https://www.uibk.ac.at/fakultaeten/rechtswissenschaftliche/uk-it-recht-digitalisierung/',
      title: 'Universität Innsbruck, Februar 2022',
      description: 'Lektor: IT Recht und Digitalisierung',
      image: 'assets/events/uni-innsbruck.jpg'
    },
    {
      link: 'https://www.nuernberger.at/',
      title: 'Nürnberger Versicherung, November 2021',
      description: 'Workshop: Blockchain in the Insurance Industry',
      image: 'assets/events/nuernberger.jpg'
    },
    {
      link: 'https://www.fh-kufstein.ac.at/Veranstaltungen/Veranstaltungsreihen/Management-Forum-Kufstein',
      title: 'Management Forum Kufstein, November 2021',
      description: 'Keynote: Blockchain as a Competitive Advantage of Enterprises',
      image: 'assets/events/FHKufsteinlogo.jpg'
    },
    {
      link: 'https://tedxinnsbruck.com/',
      title: 'TEDx Innsbruck, September 2021',
      description: 'Keynote: Digitalisierung, Blockchain und Decentralised Finance',
      image: '../../assets/events/tedx.jpg'
    },
    {
      link: 'https://www.tirol.wifi.at/kurs/34190x-general-management',
      title: 'Wifi Tirol, Juni 2021',
      description: 'Lektor: Investition und Finanzierung',
      image: '../../assets/events/wifi.jpg'
    },
    {
      link: 'https://www.tirol.wifi.at/kurs/30141x-ausbildung-human-resource-business-partner',
      title: 'Wifi Tirol, Mai 2021',
      description: 'Lektor: Digitale Transformation',
      image: '../../assets/events/wifi.jpg'
    },
    {
      link: 'https://www.fh-kufstein.ac.at',
      title: 'FH Kufstein, April 2021',
      description: 'Lektor: Digitalisierung und Blockchain',
      image: '../../assets/events/FHKufsteinlogo.jpg'
    },
    {
      link: 'https://www.wj-bremen.de/',
      title: 'Wirtschaftsbund Bremen, April 2021',
      description: 'Workshop: Wie können Firmen von der Blockchain profitieren?',
      image: '../../assets/events/wjbremen.jpg'
    },
    {
      link: 'https://www.standort-tirol.at/',
      title: 'Standort Agentur Tirol, Oktober 2020',
      description: 'Workshop: Wie können KMUs von der Blockchain profitieren?',
      image: '../../assets/events/standortagentur.jpg'
    },
    {
      link: 'https://www.deutscher-it-leiterkongress.de/',
      title: 'Deutscher IT Leiter Kongress, September 2020',
      description: 'Keynote: Blockchain: Hit oder Hype?',
      image: '../../assets/events/dilk.jpg'
    },
    {
      link: 'https://www.fh-kufstein.ac.at/newsroom/Winter-Sports-Congress-20202',
      title: 'Winter Sports Congress, März 2020',
      description: 'Keynote: Digitalisierung im Tourismus und Wintersport',
      image: '../../assets/events/FHKufsteinlogo.jpg'
    },
    {
      link: 'https://skinnovation.io/',
      title: 'Skinnovation, März 2020',
      description: 'Panel: Digitalisierung im Tourismus und Wintersport',
      image: '../../assets/events/skinnovation.jpg'
    },
    {
      link: 'https://presse.salzburg-ag.at/News.aspx',
      title: 'Salzburg AG, Februar 2020',
      description: 'Keynote: Blockchain im Energiesektor: Wenn jeder Haushalt zum Kraftwerk wird',
      image: '../../assets/events/salzburg_ag.jpg'
    },
    {
      link: 'https://www.oenb.at/',
      title: 'Österreichische Nationalbank, Februar 2020',
      description: 'Keynote & Panel: The State of Crypto Finance in Austria',
      image: '../../assets/events/onb.jpg'
    },
    {
      link: 'assets/events/IT_recht.pdf',
      title: 'Universität Innsbruck, Februar 2020',
      description: 'Lektor: IT Recht und Digitalisierung',
      image: 'assets/events/uni-innsbruck.jpg'
    },
    {
      link: 'https://www.euromoneyconferences.com/event/a01D000001Aj93bIAB/the-central-and-eastern-european-forum-2020',
      title: 'Euromoney, Jänner 2020',
      description: 'Panel: The Future of Finance',
      image: 'assets/events/european-conferences.jpg'
    },
    {
      link: 'https://www.uibk.ac.at/public-relations/presse/archiv/2019/1200/',
      title: 'Universität Innsbruck, Oktober 2019',
      description: 'Keynote: Blockchain, Ende des Hypes oder erst der Beginn?',
      image: 'assets/events/uni-innsbruck.jpg'
    },
    {
      link: 'https://avco.at/events/avco-jahrestagung-2019/',
      title: 'Austrian Venture Capital Conference, Oktober 2019',
      description: 'Panel: ICOs und STOs als alternative Finanzierungsinstrumente',
      image: 'assets/events/avco.at.jpg'
    },
    {
      link: 'https://www.eventbrite.de/e/im-kaminzimmer-dr-google-or-how-i-learned-to-love-the-data-tickets-62907173048',
      title: 'Start Up Werkstätte Wattens, September 2019',
      description: 'Panel: Datensicherheit durch Blockchaintransparenz',
      image: 'assets/events/werkstaette_wattens.jpg'
    },
    {
      link: 'https://clubalpbach.li/efa19',
      title: 'Forum Alpbach, August 2019',
      description: 'Fire Side Discussion: Financial Innovation in Europe',
      image: 'assets/events/alpbach.jpg'
    },
    {
      link: 'https://lfuonline.uibk.ac.at/public/lfuonline_lv.details?sem_id_in=19S&lvnr_id_in=955409',
      title: 'Universität Innsbruck, Juni 2019',
      description: 'Lektor: Cryptofinance und FinTech',
      image: 'assets/events/uni-innsbruck.jpg'
    },
    {
      link: 'http://www.bindergroesswang.at/events/impulse/digitale-stadt-digitale-nation-wie-weit-ist-wien-wie-weit-' +
        'ist-oesterreich-und-wie-weit-sind-oesterreichs-unternehmen-kopie-1/',
      title: 'Binder Grösswang, Mai 2019',
      description: 'Panel: Digitale Stadt, digitale Nation: Quo vadis felix Austria?',
      image: 'assets/events/binder-groesswang-2019.jpg'
    },
    {
      link: 'https://www.wirtschaftamsee.com/',
      title: 'Wirtschaft am See, Mai 2019',
      description: 'Panel: Blockchaintechnologie als Wettbewerbsvorteil',
      image: 'assets/events/wirtschaftamsee.jpg'
    },
    {
      link: 'https://www.blockchain-real.at/',
      title: 'Blockchain Real Konferenz, Mai 2019',
      description: 'Keynote & Panel: Bockchaintechnologie im Immobiliensektor',
      image: 'assets/events/blockchain-real.jpg'
    },
    {
      link: 'https://anonsummit.io/',
      title: 'Anon Blockchain Summit, April 2019',
      description: 'Moderator & Panel: ICOs, IEOs, STOs, what’s next for start up financing?',
      image: 'assets/events/anon.jpg'
    },
    {
      link: 'https://www.londonfintechsummit.com/',
      title: 'London FinTech Summit, März 2019',
      description: 'Keynote: Blockchain as a game changer in FinTech',
      image: 'assets/events/fintech.jpg'
    },
    {
      link: 'http://www.hak-schwaz.tsn.at/neu/index.php/244-tdw2019-report',
      title: 'Tag der Wirtschaft, Jänner 2019',
      description: 'Keynote: 10 Lektionen für Glück und Erfolg',
      image: 'assets/events/tdw2019_after.jpg'
    },
    {
      link: 'https://www.managementclub.at/veranstaltungen/aktuelle-veranstaltungen/veranstaltungs-details/?id=914294998',
      title: 'Management Club, Oktober 2018',
      description: 'Keynote: Digitale Chancen der Blockchain',
      image: 'assets/events/miess.jpeg'
    },
    {
      link: 'https://avco.at/events/das-war-die-avco-jahrestagung-2018/',
      title: 'Austrian Venture Capital Association, Oktober 2018',
      description: 'Panel: Start Up Finanzierung, ICOs, Blockchain',
      image: 'assets/events/avco.jpg'
    },
    {
      link: 'https://www.industrymeetsmakers.com/blockchain-beachcamp-20-09-2018',
      title: 'Industry meets Makers, September 2018',
      description: 'Keynote: Die Blockchain Revolution',
      image: 'assets/events/static1.squarespace.com2_.jpg'
    },
    {
      link: 'http://investorsday.at',
      title: 'Austrian Business Angels Investors Day, Juni 2018',
      description: 'Keynote & Panel: Alternative Start Ups Finanzierungen auf der Blockchain',
      image: 'assets/events/investors_day.jpg'
    },
    {
      link: 'http://nus.edu.sg/',
      title: 'Singapore National University, Juni 2018',
      description: 'Panel: Tech and Crypto',
      image: 'assets/events/nus.jpg'
    },
    {
      link: 'https://raiffeisen2018.de/raiffeisen-jahr-2018',
      title: 'Raiffeisen 200 Jahre Jubiläum, Juni 2018',
      description: 'Panel: Die Zukunft des Bankensektors',
      image: 'assets/events/BLICKFANG_0351.jpg'
    },
    {
      link: 'https://twitter.com/futuramasummit',
      title: 'Futurama Blockchain Innovators Summit, Mai 2018',
      description: 'Don\'t miss the future',
      image: 'assets/events/futurama.jpg'
    },
    {
      link: 'https://pioneers.io/events/pioneers18#/',
      title: 'Pioneers, Mai 2018',
      description: 'Blurred Frontiers',
      image: 'assets/events/pioneers.jpg'
    },
    {
      link: 'https://4gamechangers.io/',
      title: '4Gamechanger, April 2018',
      description: 'Das 4MECHANGERS FESTIVAL ist die Bühne für Webstars, die Drehscheibe' +
        'für zukunftsorientierte digitale Projekte...',
      image: 'assets/events/4GAMECHANGERS-Festival-2018-Teil-2-Marx-Halle-15-von-118.jpg'
    }
  ];

  constructor() { }
}
