export class LanguageDefinitionsDe {
  public navigation_home: string = "Home";
  public navigation_about: string = "Über Chris";
  public navigation_themes: string = "Themen";
  public navigation_vision: string = "Vision";
  public navigation_events: string = "Events";
  public navigation_contact: string = "Kontakt";

  public home_about_header: string = "Über Chris";
  public home_about_content1: string = "Chris Miess war nach seinem Studium an der LSE und YALE University als Investment Banker für Morgan Stanley und Goldman Sachs tätig. Dort beriet er Banken und FinTech Firmen zum Thema Kapitalmärkte und Mergers und Acquisitions (M&A).";
  //public home_about_content2: string = "Im nächsten Schritt war Chris Miess CFO des Singapur Blockchain Start Ups TenX, wo er deren Seedround sowie deren USD 80 Millionen ICO leitete. Dieser ICO wurde als der größte Blockchain Fundraise in ganz Asien bekannt.";
  public home_about_content3: string = "Daraufhin gründete Chris Miess die Blockchainfirma ICONIC und leitet diese seither als deren CEO. ICONIC berät Firmen und Start Ups zum Thema Blockchain Technologie, ICOs und STOs. Außerdem investiert ICONIC in Blockchain Start Ups.";
  public home_about_content4: string = "Das amerikanische Forbes Magazin zeichnete Miess aus und nahm ihn in Gruppe der Forbes 30 under 30 im Bereich Finanzen auf.";
  public home_about_content5: string = "Aufgrund seiner Reputation im Finanzmarkt wurde Chris Miess in den FinTech Rat der Österreichischen Regierung berufen, um dort aktiv die Gestaltung und Entwicklung des Themas Kryptowährungen, ICOs und Blockchain zu begleiten.";
  public home_about_content6: string = "Chris Miess tritt regelmäßig als Speaker zum Thema FinTech, Blockchain und Kryptowährungen auf. ";
  public home_about_quote: string = "„NEBEN DER FÜHRUNG MEINES UNTERNEHMENS IST ES MIR EINE HERZENSANGELEGENHEIT, MEIN WISSEN MIT ANDEREN MENSCHEN ZU TEILEN“";
  public home_about_signature: string = "Chris Miess";

  public home_themes_header = 'Ausgewählte Themen';
  public home_themes_blockchain_header: string = "Blockchain";
  public home_themes_blockchain_description_upper: string = "Warum ist Blockchain nicht nur für die Finanzwelt bahnbrechend, sondern für ganz viele andere Industrien. Von Datenspeicherung bis hin zu Gesundheit, bis hin zum Grundbuch.";
  public home_themes_blockchain_description_lower: string = "Wie funktioniert das Geschäft mit Bitcoin und Kryptowährungen? Was sind NFTs, ICOs und DeFI? Wird die Finanzwelt in der Zukunft komplett dezentralisiert und werden Banken verschwinden?";
  public home_themes_ai_header: string = "Künstliche Intelligenz";
  public home_themes_ai_description_upper: string = "Was genau ist Künstliche Intelligenz? Welche Auswirkungen hat sie auf die Arbeitswelt und auf unser tägliches Leben? Wie wird sie in der Zukunft eingesetzt?";
  public home_themes_ai_description_lower: string = "Wie funktioniert Machine Learning und Deep Learning? Welche Anwendungen gibt es bereits und wie kann KI im eigenen Unternehmen eingesetzt werden?";
  public home_cryptofit_header: string = "#blockchain_fit und #KI_Fit";
  public home_cryptofit_subheader: string = "MEINE VISION";
  public home_cryptofit_content1: string = "Mein Ziel ist es, die komplette Technologien wie Blockchain und Künstliche Intelligenz einfach und verständlich zu erklären, sodass es jeder versteht. Ganz ohne Vorwissen.";
  public home_cryptofit_content2: string = "Auf den ersten Blick ist es sehr verwirrend, Kryptowährungen, ICOs, STOs, Blockchains. Was steckt wirklich hinter der Technolgie? Ist sie wirklich so revolutionär wie jeder sagt? Oder ist es nur Abzocke mit Kryptowährungen?";
  public home_cryptofit_content3: string = "Mein Ziel ist es, Blockchain auf simple Art und Weise zu erklären, sodass jeder versteht, worum es geht. Und zwar ganz ohne Vorwissen";
  public home_cryptofit_quote: string = "„Wenn du es nicht einfach erklären kannst, hast du es nicht verstanden“";
  public home_cryptofit_quote_albert: string = "Albert Einstein";

  public home_events_header: string = "Events mit Chris";

  public home_forbes_description: string = "Vor kurzem wurde Chris vom renommierten Forbes Magazin ausgezeichnet und in den Kreis der 30 unter 30 im Bereich Finanzen aufgenommen.";

  public home_contact_header: string = "KONTAKT";
}
