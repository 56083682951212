import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { LegalnoticeComponent } from './legalnotice/legalnotice.component';
import { PrivacypolicyComponent } from './privacypolicy/privacypolicy.component';
import { StartComponent } from './home/start/start.component';
import { AboutComponent } from './home/about/about.component';
import { ThemesComponent } from './home/themes/themes.component';
import { VisionComponent } from './home/vision/vision.component';
import { EventsComponent } from './home/events/events.component';
import { ContactComponent } from './home/contact/contact.component';

const routes: Routes = [
  {
    path: 'home',
    component: HomeComponent
  },
  { path: 'legalnotice', component: LegalnoticeComponent },
  { path: 'privacypolicy', component: PrivacypolicyComponent },
  { path: '', redirectTo: 'home', pathMatch: 'full' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes,
      {scrollPositionRestoration: 'top', useHash:true,
      anchorScrolling: 'enabled',
      scrollOffset: [0, 150]
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
