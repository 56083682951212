export class LanguageDefinitionsEn {
    public navigation_home: string = "Home";
    public navigation_about: string = "About Chris";
    public navigation_themes: string = "Themes";
    public navigation_vision: string = "Vision";
    public navigation_events: string = "Events";
    public navigation_contact: string = "Contact";

    public home_about_header: string = "Über Chris";
    public home_about_content1: string = "Chris Miess war der CFO von TenX bei einem 80 Mio. USD ICO. Diese ICO war die größte in Asien und hat den Standard für viele zukünftige ICOs gesetzt. Vor seiner Tätigkeit bei TenX war Chris viele Jahre im Investment Banking bei Goldman Sachs in London tätig. Er beriet FinTech-Unternehmen bei Kapitalmarkt- und M & A-Transaktionen. Zu seinen Kunden zählen FinTechs, Banken, Kreditkartenunternehmen und andere Finanzinstitute. Chris ist auch der Gründer und CEO von ICONIC Capital, der führenden ICO Beratungs- und Investmentgesellschaft in Asien. In jüngster Zeit war ICONIC maßgeblich an der erfolgreichen Durchführung von Projekten wie GIFTO, Appcoins, Aidcoin, Datum und Quantstamp beteiligt. Insgesamt hat ICONIC mehr als eine viertel Milliarde Dollar für seine Kunden gesammelt.";
    public home_about_content2: string = "Chris Miess wurde kürzlich zum Mitglied des Fintech Advisory Board der österreichischen Regierung ernannt. Er berät insbesondere im Vorfeld der österreichischen EU-Ratspräsidentschaft rund um Blockchain-Themen, ICO-Regulierung und Regulierungssandboxen.";
    public home_about_content3: string = "Chris ist ein anerkannter Redner, Investor und Berater, der sich auf die Auswirkungen neuer Technologien wie Blockchain und Kryptowährungen auf Wirtschaft, Gesellschaft und Regierung konzentriert.";
    public home_about_content4: string = "Wie die Technologie hinter Bitcoin das Geldgeschäft und die Welt verändert.";
    public home_about_content5: string = "Er ist ein aktiver Berater und Investor in einer Reihe von wachstumsstarken Startups und Blockchain-Protokollen.";
    public home_about_content6: string = "";
    public home_about_quote: string = "„NEBEN DER FÜHRUNG MEINES UNTERNEHMENS IST ES MIR EINE HERZENSANGELEGENHEIT MEIN WISSEN NICHT NUR FÜR MICH ZU BEHALTEN, SONDERN MIT ANDEREN MENSCHEN ZU TEILEN!“";
    public home_about_signature: string = "Chris Miess";

    public home_themes_header: string = "Meine Themengebiete:";
    public home_themes_blockchain_header: string = "BLOCKCHAIN";
    public home_themes_blockchain_description: string = "WAS IST BLOCKCHAIN, WIE FUNKTIONIERT ES UND WARUM REVOLUTIONIERT ES DIE FINANZWELT?";
    public home_themes_crypto_header: string = "KRYPTOWÄHRUNGEN";
    public home_themes_crypto_description: string = "WIE FUNKTIONIERT DAS GESCHÄFT MIT KRYPTOWÄHRUNGEN UND IN WELCHE IST ES DERZEIT SINNVOLL ZU INVESTIEREN?";
    public home_themes_icos_header: string = "ICOS";
    public home_themes_icos_description: string = "SIND ICOS DIE ZUKUNFT DER FINANZIERUNG? WIE NUTZT MAN UTILITY TOKENS UND SECURITY TOKENS? WIE SIND ICOS REGULIERT? WIE MACHT MAN SEINEN EIGENEN ICO? LÖST ICO INVESTING VC INVESTING AB?";

    public home_cryptofit_header: string = "#CRYPTOFIT";
    public home_cryptofit_subheader: string = "MEINE VISION";
    public home_cryptofit_content1: string = "MEIN TRAUM IST ES DIE WELT #CRYPTOFIT ZU MACHEN.";
    public home_cryptofit_content2: string = "AUF DEN ERSTEN BLICK ERSCHEINT ES HOFFNUNGSLOS, FONDS, ICO’S, BLOCKCHAINS, KRYPTOWÄHRUNGEN, … – NEUE BEGRIFFE, NEUE WÄHRUNGEN, EINE NEUE WELT.";
    public home_cryptofit_content3: string = "DAS IST DER STARTSCHUSS FÜR CHRIS!";
    public home_cryptofit_quote: string = "ER BETRITT DIE BÜHNE UND ERKLÄRT DIESE NEUE WELT AUF SO SIMPLE UND VERSTÄNDLICHE ART UND WEISE, DASS ES JEDER , AUCH OHNE VORWISSEN, VERSTEHT";
    public home_themes_defi: string = 'DeFi steht für Decentralised Finance. Was steckt dahinter? Wird die Finanzwelt in der Zukunft komplett dezentralisiert? Werden Banken verschwinden?';

    public home_events_header: string = "Events mit Chris";

    public home_forbes_description: string = "Miess war in der Abteilung M&A bei Goldman Sachs in London fürout Fintech-Kunden zuständig. Nach seinem Abschied aus der Corporate-Welt gründete er ICONIC, eine führende Be­ratungs- und Investmentgesellschaft für ICOs in Singapur. Zudem investiert ICONIC erfolgreich in Projekte.";

    public home_contact_header: string = "KONTAKT";
}